import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Close, Hamburger, Logo, Plus, Cubes, MobileCubes, Arrow, Marker } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,

    scrolled: false,
    headerShow: true,
    sectorsSubNav: false,
    servicesSubNav: false,
    sectorsHover: false,
    servicesHover: false,
    directorySubNav: false,
    companySubNav: false,
    directoryHover: false,
    companyHover: false,

    direction: 0,
    prevDirection: 0,
    prevScroll: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
  }

  onScroll = (e) => {
    let { direction, prevDirection, prevScroll, headerShow, scrolled } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    // Make sure they scroll more than 5px
    if(Math.abs(prevScroll - curScroll) <= 5) return;

    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    }
    else if (window.scrollY < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      if (direction === 2 && curScroll > 120) {
        headerShow = false
      }
      else if (direction === 1) {
        headerShow = true
      }
    }

    // Bottom of page
    if (window.innerHeight + curScroll + 10 >= document.body.scrollHeight) {
      headerShow = true
    }

    prevScroll = curScroll;

    requestAnimationFrame(() => {
      this.setState({
        scrolled, headerShow,  direction, prevDirection, prevScroll
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ 
      offCanvas: false,
      sectorsHover: false,
      servicesHover: false,
      directoryHover: false,
      companyHover: false,
    })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {
    const headerType = this.props.pageContext?.header || 'transparent'

    let { offCanvas, scrolled, headerShow, servicesSubNav, sectorsSubNav, sectorsHover, servicesHover, directoryHover, directorySubNav, companyHover, companySubNav } = this.state

    const { sectorsImage, servicesImage, directoryImage, companyImage } = this.props.data.wp.siteOptions.acf
    const { services, sectors, latestResource } = this.props.data

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled || headerType === 'blue') headerClass += ' header--inverse'
    if (headerType === 'transparent') headerClass += ' header--transparent'
    if (headerType === 'transparent-dark') headerClass += ' header--transparent-dark'
    if (!headerShow) headerClass += ' header--hide'
    if (offCanvas) headerClass += ' header--off-canvas-active'

    return (
      <>
        <header className={headerClass}>
          <div className='header__wrapper'>
            <div className='header__inner'>
              <div className='header__logo-nav'>
                <Link to='/' title='M3 Property' className='header__logo' {...props}>
                  <Logo color={`#00164E`} short={true} />
                </Link>
                <nav className='header__nav'>
                  <ul>
                    <li onMouseEnter={() => this.setState({ servicesHover: true })} onMouseLeave={() => this.setState({ servicesHover: false })}>
                      <button type='button'>Services</button>
                      <div className={`dropdown ${servicesHover ? 'dropdown--active' : ''}`}>
                        <div className='dropdown__inner'>
                          <div className='dropdown__image'>
                            { servicesImage?.localFile && <GatsbyImage loading='lazy' image={getImage(servicesImage.localFile)} alt='Services' /> }
                          </div>
                          <div className='dropdown__menu'>
                            <ul>
                              { services?.nodes?.map((el, i) => (
                                <li key={i}>
                                  <Link to={el.uri} {...props}>
                                    <span className='text'>{el.title}</span>
                                    <span className='circle'>
                                      <Arrow color='#00164E' />
                                    </span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='dropdown__resource'>
                            <h4 className='dropdown__resource-title'>Related Insight</h4>
                            { latestResource?.nodes?.map((el, i) => (
                              <div className='card card--insight' key={i}>
                                <div className='card__content'>
                                  <div className='card__title'>
                                    <Link to={el.uri} {...props}><h4 dangerouslySetInnerHTML={{ __html: el.title }} /></Link>
                                  </div>
                                  <div className='card__links'>
                                    <span className='card__location'>
                                      <Marker color='#00164E' />
                                      {el.locations?.nodes[0].name}
                                    </span>
                                    <Link className='card__arrow' to={el.uri} {...props}><Arrow color='#00164E' /></Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li onMouseEnter={() => this.setState({ sectorsHover: true })} onMouseLeave={() => this.setState({ sectorsHover: false })}>
                      <button type='button'>Sectors</button>
                      <div className={`dropdown ${sectorsHover ? 'dropdown--active' : ''}`}>
                        <div className='dropdown__inner'>
                          <div className='dropdown__image'>
                            { sectorsImage?.localFile && <GatsbyImage loading='lazy' image={getImage(sectorsImage.localFile)} alt='Sectors' /> }
                          </div>
                          <div className='dropdown__menu'>
                            <ul>
                              { sectors?.nodes?.map((el, i) => (
                                <li key={i}>
                                  <Link to={el.uri} {...props}>
                                    <span className='text'>{el.title}</span>
                                    <span className='circle'>
                                      <Arrow color='#00164E' />
                                    </span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className='dropdown__resource'>
                            <h4 className='dropdown__resource-title'>Related Insight</h4>
                            { latestResource?.nodes?.map((el, i) => (
                              <div className='card card--insight' key={i}>
                                <div className='card__content'>
                                  <div className='card__title'>
                                    <Link to={el.uri} {...props}><h4 dangerouslySetInnerHTML={{ __html: el.title }} /></Link>
                                  </div>
                                  <div className='card__links'>
                                    <span className='card__location'>
                                      <Marker color='#00164E' />
                                      {el.locations?.nodes[0].name}
                                    </span>
                                    <Link className='card__arrow' to={el.uri} {...props}><Arrow color='#00164E' /></Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li><Link to='/insights/' {...props}>Insights</Link></li>
                    <li onMouseEnter={() => this.setState({ directoryHover: true })} onMouseLeave={() => this.setState({ directoryHover: false })}>
                      <button type='button'>Directory</button>
                      <div className={`dropdown ${directoryHover ? 'dropdown--active' : ''}`}>
                        <div className='dropdown__inner'>
                          <div className='dropdown__image'>
                            { directoryImage?.localFile && <GatsbyImage loading='lazy' image={getImage(directoryImage.localFile)} alt='Directory' /> }
                          </div>
                          <div className='dropdown__menu'>
                            <ul>
                              <li>
                                <Link to='/directory/' {...props}>
                                  <span className='text'>Staff Directory</span>
                                </Link>
                              </li>
                              <li>
                                <Link to='/contact/#Melbourne' {...props}>
                                  <span className='text'>Find an office</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className='dropdown__resource'>
                            <h4 className='dropdown__resource-title'>Related Insight</h4>
                            { latestResource?.nodes?.map((el, i) => (
                              <div className='card card--insight' key={i}>
                                <div className='card__content'>
                                  <div className='card__title'>
                                    <Link to={el.uri} {...props}><h4 dangerouslySetInnerHTML={{ __html: el.title }} /></Link>
                                  </div>
                                  <div className='card__links'>
                                    <span className='card__location'>
                                      <Marker color='#00164E' />
                                      {el.locations?.nodes[0].name}
                                    </span>
                                    <Link className='card__arrow' to={el.uri} {...props}><Arrow color='#00164E' /></Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li onMouseEnter={() => this.setState({ companyHover: true })} onMouseLeave={() => this.setState({ companyHover: false })}>
                      <Link to='/company/' {...props}>Company</Link>
                      <div className={`dropdown ${companyHover ? 'dropdown--active' : ''}`}>
                        <div className='dropdown__inner'>
                          <div className='dropdown__image'>
                            { companyImage?.localFile && <GatsbyImage loading='lazy' image={getImage(companyImage.localFile)} alt='Directory' /> }
                          </div>
                          <div className='dropdown__menu'>
                            <ul>
                              <li>
                                <Link to='/company/' {...props}>
                                  <span className='text'>About Us</span>
                                </Link>
                              </li>
                              <li>
                                <Link to='/careers/' {...props}>
                                  <span className='text'>Careers</span>
                                </Link>
                              </li>
                              <li>
                                <Link to='/graduate-program/' {...props}>
                                  <span className='text'>Graduate Program</span>
                                </Link>
                              </li>
                              <li>
                                <Link to='/contact/' {...props}>
                                  <span className='text'>Contact</span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className='dropdown__resource'>
                            <h4 className='dropdown__resource-title'>Related Insight</h4>
                            { latestResource?.nodes?.map((el, i) => (
                              <div className='card card--insight' key={i}>
                                <div className='card__content'>
                                  <div className='card__title'>
                                    <Link to={el.uri} {...props}><h4 dangerouslySetInnerHTML={{ __html: el.title }} /></Link>
                                  </div>
                                  <div className='card__links'>
                                    <span className='card__location'>
                                      <Marker color='#00164E' />
                                      {el.locations?.nodes[0].name}
                                    </span>
                                    <Link className='card__arrow' to={el.uri} {...props}><Arrow color='#00164E' /></Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <Link className='header__btn btn' to='/valuation/'>
                Request a valuation <Plus color={`#FFFFFF`} />
              </Link>
              <button onClick={this._toggleOffCanvas} className='header__hamburger'>
                { offCanvas ? <Close /> : <Hamburger /> }
              </button>
            </div>
          </div>
          <div className='header__padding' />
        </header>
        <div className={`off-canvas ${offCanvas && 'active'}`}>
          <div className='off-canvas__header'>
            <div className='off-canvas__inner'>
              <Link to='/' title='M3 Property' className='off-canvas__logo' {...props}>
                <Logo color={`#00164E`} short={true} />
              </Link>
              <button onClick={this._toggleOffCanvas} className='off-canvas__hamburger'>
                <Close color='#00164E' />
              </button>
            </div>
          </div>
          <Link className='off-canvas__btn btn' to='/valuation/' {...props}>
            Request a valuation <Plus color={`#FFFFFF`} />
          </Link>
          <div className='off-canvas__nav'>
            <div className='off-canvas__inner'>
              <ul>
                <li>
                  <button type='button' onClick={() => this.setState({ servicesSubNav: true })}>
                    <span>Services</span>
                    <Arrow color='#00164E' />
                  </button>
                </li>
                <li>
                  <button type='button' onClick={() => this.setState({ sectorsSubNav: true })}>
                    <span>Sectors</span>
                    <Arrow color='#00164E' />
                  </button>
                </li>
                <li>
                  <Link to='/insights/' {...props}>
                    <span>Insights</span>
                    <Arrow color='#00164E' />
                  </Link>
                </li>
                <li>
                  <button type='button' onClick={() => this.setState({ directorySubNav: true })}>
                    <span>Directory</span>
                    <Arrow color='#00164E' />
                  </button>
                </li>
                <li>
                  <button type='button' onClick={() => this.setState({ companySubNav: true })}>
                    <span>Company</span>
                    <Arrow color='#00164E' />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className={`off-canvas__sub-nav ${servicesSubNav ? 'active' : ''}`}>
            <div className='off-canvas__inner'>
              <button className='off-canvas__back' type='button' onClick={() => this.setState({ servicesSubNav: false })}>
                <span className='circle'>
                  <Arrow color='#00164E' />
                </span>
                <span className='text'>Back to Main</span>
              </button>
              <div className='off-canvas__menu'>
                <h4>Services</h4>
                <ul>
                  { services?.nodes?.map((el, i) => (
                    <li key={i}>
                      <Link to={el.uri} {...props}>
                        <span className='text'>{el.title}</span>
                        <Arrow color='#00164E' />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={`off-canvas__sub-nav ${sectorsSubNav ? 'active' : ''}`}>
            <div className='off-canvas__inner'>
              <button className='off-canvas__back' type='button' onClick={() => this.setState({ sectorsSubNav: false })}>
                <span className='circle'>
                  <Arrow color='#00164E' />
                </span>
                <span className='text'>Back to Main</span>
              </button>
              <div className='off-canvas__menu'>
                <h4>Sectors</h4>
                <ul>
                  { sectors?.nodes?.map((el, i) => (
                    <li key={i}>
                      <Link to={el.uri} {...props}>
                        <span className='text'>{el.title}</span>
                        <Arrow color='#00164E' />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={`off-canvas__sub-nav ${directorySubNav ? 'active' : ''}`}>
            <div className='off-canvas__inner'>
              <button className='off-canvas__back' type='button' onClick={() => this.setState({ directorySubNav: false })}>
                <span className='circle'>
                  <Arrow color='#00164E' />
                </span>
                <span className='text'>Back to Main</span>
              </button>
              <div className='off-canvas__menu'>
                <h4>Directory</h4>
                <ul>
                  <li>
                    <Link to='/directory/' {...props}>
                      <span className='text'>Staff Directory</span>
                      <Arrow color='#00164E' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact/#Melbourne' {...props}>
                      <span className='text'>Find an office</span>
                      <Arrow color='#00164E' />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`off-canvas__sub-nav ${companySubNav ? 'active' : ''}`}>
            <div className='off-canvas__inner'>
              <button className='off-canvas__back' type='button' onClick={() => this.setState({ companySubNav: false })}>
                <span className='circle'>
                  <Arrow color='#00164E' />
                </span>
                <span className='text'>Back to Main</span>
              </button>
              <div className='off-canvas__menu'>
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to='/company/' {...props}>
                      <span className='text'>About Us</span>
                      <Arrow color='#00164E' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/company/#our-values' {...props}>
                      <span className='text'>Our Values</span>
                      <Arrow color='#00164E' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/careers/' {...props}>
                      <span className='text'>Careers</span>
                      <Arrow color='#00164E' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/graduate-program/' {...props}>
                      <span className='text'>Graduate</span>
                      <Arrow color='#00164E' />
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact/' {...props}>
                      <span className='text'>Contact</span>
                      <Arrow color='#00164E' />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='header__cubes header__cubes--mobile'>
          <MobileCubes color='#F2F1F1' />
        </div>
        <div className='header__cubes header__cubes--desktop'>
          <Cubes color='#F2F1F1' />
        </div>
      </>
    )
  }
}

export default Header
