import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class Newsletter extends Component {
  state = {
    email: '',
    result: {},
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  handleSubmit = async (e) => {
    e.preventDefault()

    const result = await addToMailchimp(this.state.email)
    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {

    let props = {
      name: 'newsletter',
      onSubmit: this.handleSubmit
    }

    if (this.state.result === 'success') {
      return (
        <p className='form--success'>Thanks for subscribing.</p>
      )
    }

    return (
      <form {...props}>
        <input type='email' placeholder='Your Email Address' name='email' onChange={this.handleChange} required />
        <button type='submit' className='btn btn--green'>Subscribe</button>
      </form>
    )
  }
}

export default Newsletter