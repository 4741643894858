import React, { Component } from 'react'

import Link from '../utils/link'
import Newsletter from './newsletter'
import { Facebook, Instagram, LinkedIn, LogoShort, Youtube, Twitter, Triangle } from './icons'

const LOCATIONS = [
  { name: 'Melbourne', tel: '+61 3 9605 1000' },
  { name: 'Sydney', tel: '+61 2 8234 8100' },
  { name: 'Adelaide', tel: '+61 8 7099 1800' },
  { name: 'Brisbane', tel: '+61 7 3620 7900' },
]

class Footer extends Component {

  state = {
    sectors: false,
    services: false,
    resources: false,
    directory: false,
    company: false,

    location: LOCATIONS[0],
  }

  renderServices() {
    const { services } = this.props.data
    return (
      <div>
        <button className={this.state.services ? 'active' : ''} type='button' onClick={() => this.setState({ services: !this.state.services })}>
          Services
          <Triangle color='#FFFFFF' />
        </button>
        <ul className={this.state.services ? 'active' : ''}>
          { services?.nodes?.map((el, i) => (
            <li key={i}>
              <Link to={el.uri}>{el.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderSectors() {
    const { sectors } = this.props.data
    return (
      <div>
        <button className={this.state.sectors ? 'active' : ''} type='button' onClick={() => this.setState({ sectors: !this.state.sectors })}>
          Sectors
          <Triangle color='#FFFFFF' />
        </button>
        <ul className={this.state.sectors ? 'active' : ''}>
          { sectors?.nodes?.map((el, i) => (
            <li key={i}>
              <Link to={el.uri}>{el.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderResources() {
    return (
      <div>
        <button className={this.state.resources ? 'active' : ''} type='button' onClick={() => this.setState({ resources: !this.state.resources })}>
          Insights
          <Triangle color='#FFFFFF' />
        </button>
        <ul className={this.state.resources ? 'active' : ''}>
          <li><Link to='/insights/'>News</Link></li>
          <li><Link to='/insights/'>Research</Link></li>
        </ul>
      </div>
    )
  }

  renderDirectory() {
    return (
      <div>
        <button className={this.state.directory ? 'active' : ''} type='button' onClick={() => this.setState({ directory: !this.state.directory })}>
          Directory
          <Triangle color='#FFFFFF' />
        </button>
        <ul className={this.state.directory ? 'active' : ''}>
          <li><Link to='/directory/'>Staff Directory</Link></li>
          <li><Link to='/contact'>Find an Office</Link></li>
          
        </ul>
      </div>
    )
  }
  renderCompany() {
    return (
      <div>
        <button className={this.state.company ? 'active' : ''} type='button' onClick={() => this.setState({ company: !this.state.company })}>
          Company
          <Triangle color='#FFFFFF' />
        </button>
        <ul className={this.state.company ? 'active' : ''}>
          <li><Link to='/company/'>About Us</Link></li>
          <li><Link to='/careers/'>Careers</Link></li>
          <li><Link to='/graduate-program/'>Graduate Program</Link></li>
          <li><Link to='/contact/'>Contact</Link></li>
        </ul>
      </div>
    )
  }

  render() {

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__mobile-logo'>
            <Link to='/'>
              <LogoShort color={'#ffffff'} />
            </Link>
          </div>

          <div className='footer__nav'>
            { this.renderServices() }
            { this.renderSectors() }
            { this.renderResources() }
            { this.renderDirectory() }
            { this.renderCompany() }
          </div>

          <div className='footer__subscribe'>
            <p>Join our mailing list for exclusive access to property Insights.</p>
            <Newsletter />
            <div className='footer__subscribe-social'>
              {/* <Link to='/'><Youtube /></Link> */}
              {/* <Link to='/'><Facebook /></Link> */}
              {/* <Link to='/'><Instagram /></Link> */}
              <Link to='https://au.linkedin.com/company/m3property'><LinkedIn /></Link>
              {/* <Link to='https://twitter.com/m3propertyinfo?lang=en'><Twitter /></Link> */}
            </div>
          </div>

          <div className='footer__bottom'>
            <div className='footer__bottom-logo'>
              <Link to='/'>
                <LogoShort color={'#ffffff'} />
              </Link>
            </div>
            
            <div className='footer__bottom-telephone'>
              <p>{ this.state.location.name } Enquiries: <Link to={`tel:${this.state.location.tel}`}>{ this.state.location.tel }</Link></p>
              <button type='button' className={this.state.locationPicker ? 'active' : ''} onClick={() => this.setState({ locationPicker: !this.state.locationPicker})}>
                <Triangle color={'#ffffff'} />
              </button>
              <ul className={this.state.locationPicker ? 'active' : ''}>
                { LOCATIONS?.map((el, i) => (
                  <li key={i}>
                    <button type='button' onClick={() => this.setState({location: el, locationPicker: false})}>{ el.name }</button>
                  </li>
                ))}
              </ul>
            </div>
            <div className='footer__bottom-links'>
              <p>© M3 Property 2024. All rights reserved.</p>
        <p><Link to='/terms-conditions/'>Terms of Use</Link><Link to='/disclaimer/'>Disclaimer</Link><Link to='/privacy-policy/'>Privacy</Link><Link to='https://atollon.com.au'>Design by Atollon</Link></p>            </div>
          </div>

        </div>
      </footer>
    )
  }
}

export default Footer
