import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      id
      uri
      title
      content
      excerpt
      date(formatString: "ddd DD MMMM")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
        }
      }
      locations {
        nodes {
          name
          slug
        }
      }
      sectors {
        nodes {
          name
          slug
        }
      }
      services {
        nodes {
          name
          slug
        }
      }
      acf {
        expertise {
          ... on WpPage {
            title
            uri
          }
        }
      }
    }
    fragment TeamCard on WpTeam {
      id
      title
      uri
      acf {
        cardImage {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 464
              )
            }
          }
        }
        expertise {
          ... on WpPage {
            title
            parentDatabaseId
            slug
          }
        }
        title
        filter
        location {
          name
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      services: allWpPage(
        filter: {parentId: {eq: "cG9zdDoxOTE="}}
        sort: {menuOrder: ASC}
      ) {
        nodes {
          title
          uri
        }
      }
      sectors: allWpPage(
        filter: {parentId: {eq: "cG9zdDoyNTE="}}
        sort: {menuOrder: ASC}
      ) {
        nodes {
          title
          uri
        }
      }
      latestResource: allWpPost(limit: 1) {
        nodes {
          ...ArticleCard
        }
      }
      wp {
        siteOptions {
          acf {
            sectorsImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 762
                  )
                }
              }
            }
            servicesImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 762
                  )
                }
              }
            }
            directoryImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 762
                  )
                }
              }
            }
            companyImage {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 762
                  )
                }
              }
            }
          }
        }
      }
    }
  `)
}

export default useLayoutQuery